import { init, reactRouterV5Instrumentation } from '@sentry/react';
import { Replay } from '@sentry/replay';
import { Integrations } from '@sentry/tracing';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useConfig } from '../ConfigProvider';

const Sentry: FC = () => {
  const history = useHistory();
  const { environment } = useConfig();

  useEffect(() => {
    const sentryEnvironment = environment || process.env.NODE_ENV;
    const enabled =
      ![
        'app.artemis.swaarm-clients.com',
        'app.ares.swaarm-clients.com',
        'app.mars.swaarm-clients.com',
        'app.demo.swaarm-clients.com',
        'app.buster.swaarm-clients.com',
        'app.saturn.swaarm-clients.com',
        'app.mercury.swaarm-clients.com',
        'app.venus.swaarm-clients.com',
        'app.phoenix.swaarm-clients.com',
      ].includes(window.location.hostname) &&
      sentryEnvironment === 'production';

    init({
      dsn: 'https://3d92a3d3fb8b4089b035f603293059f2@o195603.ingest.sentry.io/4505091011379200',
      enabled,
      environment: sentryEnvironment,
      ignoreErrors: [
        'ResizeObserver loop',
        'useRequest has caught the exception',
      ],
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: reactRouterV5Instrumentation(history),
        }),
        new Replay(),
      ],
      normalizeDepth: 10,
      release: `app-${VERSION}`,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // This sets the sample rate to be 1%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.01,
      tracesSampleRate: 0.1,
    });
  }, [environment, history]);

  return null;
};

Sentry.displayName = 'Sentry';

export default Sentry;
