import { memoize } from 'lodash/fp';
import moment from 'moment';

const locale = 'en-US';

const memoizeFormat = memoize((options, treshold = 100000000) => (value) => {
  if (value > treshold) {
    return new Intl.NumberFormat(locale, {
      maximumSignificantDigits: 4,
      minimumSignificantDigits: 4,
      notation: 'compact',
      ...options,
    }).format(value);
  } else {
    return new Intl.NumberFormat(locale, options).format(value);
  }
});

export const formatInteger = (number, options) =>
  memoizeFormat({
    maximumFractionDigits: 0,
    ...options,
  })(number);

export const formatCurrency = (number, options) =>
  memoizeFormat({
    currency: global.currency || 'USD',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    ...options,
  })(number);

export const formatDollarWithCents = (number, options) =>
  formatCurrency(number, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 2,
    ...options,
  });

export const formatDollar = formatCurrency;

export const formatPercent = (number, options) =>
  memoizeFormat(
    {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
      style: 'percent',
      ...options,
    },
    1000,
  )(number);

export const formatConversionPercent = (number, options) =>
  formatPercent(number, {
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
    ...options,
  });

export const formatDateTime = (date, format = 'YYYY-MM-DD HH:mm') =>
  moment(date).format(format);

export const formatDateTimeWithSeconds = (
  date,
  format = 'YYYY-MM-DD HH:mm:ss',
) => moment(date).format(format);

export const formatDate = (date, format = 'YYYY-MM-DD') =>
  formatDateTime(date, format);

export const formatLocalDateTime = (date) =>
  moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
