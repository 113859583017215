import {
  Checkbox,
  Grid,
  PaginationProps,
  Table,
  TableProps,
  Tooltip,
} from 'antd';
import { ColumnType, TableRowSelection } from 'antd/es/table/interface';
import classNames from 'classnames';
import {
  HTMLAttributes,
  Key,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';

import HeaderRow, { HeaderRowProps } from './HeaderRow';
import styles from './styles.module.less';
import { SortableColumnType } from './useSortedColumns';
import { VisibleColumnType } from './useVisibleColumns';

export const defaultPageSize = 50;

export const defaultRowKey = (record: Record<string, unknown>): string => {
  if ('id' in record) {
    return String(record.id);
  }

  return String(
    Object.values(record)
      .map((value) => String(value).substring(0, 50).toLowerCase())
      .filter(Boolean)
      .join('-'),
  );
};

export enum RowSelectionMode {
  All = 'all',
}

const defaultPagination: PaginationProps = {
  hideOnSinglePage: false,
  pageSize: defaultPageSize,
  showSizeChanger: false,
  showTotal: (total, range) => {
    if (total === Number.MAX_SAFE_INTEGER) {
      return `${range[0]}-${range[1]}`;
    }

    return `${range[0]}-${range[1]} of ${total}`;
  },
};

export type DataTableProps<R> = Omit<
  TableProps<R>,
  'columns' | 'rowSelection'
> & {
  columns?: (SortableColumnType<R> | VisibleColumnType<R>)[];
  striped?: boolean;
  subheader?: ReactElement;
  rowSelection?: Omit<TableRowSelection<R>, 'selectedRowKeys'> & {
    mode?: RowSelectionMode;
    selectedRowKeys?: Key[] | RowSelectionMode.All;
    onSelectTotal?: () => void;
  };
};

const DataTable = <R extends Record<string, unknown>>({
  className,
  pagination: paginationProp,
  striped,
  rowSelection: rowSelectionProp,
  columns: columnsProp,
  rowKey = defaultRowKey,
  ...rest
}: DataTableProps<R>): JSX.Element => {
  const props = rest;
  const screens = Grid.useBreakpoint();

  const columns = useMemo(
    () =>
      columnsProp?.map((column) => ({
        ...column,
        width: column?.width ? column.width : screens.xs ? 200 : undefined,
      })),
    [columnsProp, screens.xs],
  );

  const colspan = useMemo(
    () =>
      columns?.reduce<number>((colspan, column) => {
        if ('children' in column) {
          return colspan + column.children.length - 1;
        }

        return colspan;
      }, columns.length) || 0,
    [columns],
  );

  const mode = useMemo(() => {
    if (rowSelectionProp?.selectedRowKeys === RowSelectionMode.All) {
      return RowSelectionMode.All;
    }

    return undefined;
  }, [rowSelectionProp?.selectedRowKeys]);

  const selectedRowKeys = useMemo(() => {
    if (rowSelectionProp?.selectedRowKeys === RowSelectionMode.All) {
      return [];
    }

    return rowSelectionProp?.selectedRowKeys;
  }, [rowSelectionProp?.selectedRowKeys]);

  const rowSelection = useMemo(() => {
    if (!rowSelectionProp) {
      return;
    }

    return {
      ...rowSelectionProp,
      hideSelectAll: mode === RowSelectionMode.All,
      mode,
      renderCell: (
        checked: boolean,
        record: R,
        index: number,
        node: ReactNode,
      ) => {
        const { disabled, tooltip, ...rest } =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (node && 'props' in node && node.props) || {};

        const checkbox = (
          <Checkbox
            {...rest}
            checked={mode === RowSelectionMode.All || checked}
            disabled={mode === RowSelectionMode.All || disabled}
          />
        );

        if (tooltip) {
          return <Tooltip title={tooltip}>{checkbox}</Tooltip>;
        }

        return checkbox;
      },
      selectedRowKeys,
    };
  }, [mode, selectedRowKeys, rowSelectionProp]);

  const pagination = useMemo(() => {
    if (paginationProp) {
      return {
        ...defaultPagination,
        ...paginationProp,
        // https://github.com/ant-design/ant-design/issues/28413
        total:
          paginationProp.total === Number.POSITIVE_INFINITY
            ? Number.MAX_SAFE_INTEGER
            : paginationProp.total,
      };
    }

    if (paginationProp === undefined) {
      return defaultPagination;
    }

    return paginationProp;
  }, [paginationProp]);

  // TODO: Deal with onHeaderRow prop
  const onHeaderRow = useCallback(
    (
      columns: readonly ColumnType<R>[],
    ): HeaderRowProps<R> | HTMLAttributes<HTMLElement> => {
      return {
        colspan,
        columns,
        rowSelection,
        total: pagination ? pagination.total : undefined,
      };
    },
    [colspan, rowSelection, pagination],
  );

  return (
    <Table<R>
      className={classNames(
        styles.table,
        {
          [styles.striped]:
            striped && !(rest.expandable || rest.expandedRowRender),
          [styles.infinitePagination]:
            pagination && pagination.total === Number.MAX_SAFE_INTEGER,
        },
        className,
      )}
      columns={columns}
      components={{
        header: {
          row: HeaderRow,
        },
      }}
      onHeaderRow={onHeaderRow}
      pagination={pagination}
      rowKey={rowKey}
      rowSelection={rowSelection}
      scroll={{
        x: 'min-content', // firefox ellipsis issue (usually should be max-content)
      }}
      size="small"
      sortDirections={['descend', 'ascend']}
      sticky
      tableLayout="fixed"
      {...props}
    />
  );
};

DataTable.displayName = 'DataTable';

export default DataTable;
